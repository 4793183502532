import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  store (formData) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'manual/image/store',
      data: formData,
    })
  },
}
