<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Testing Manual"
      color="accent"
    >
      <v-data-table
        :headers="headers"
        :items="manualData"
        sort-by="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Manual List</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            />
            <v-btn
              color="green"
              icon
              small
              class="mt-1"
              @click="resetAndLoad"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="keyWord"
              placeholder="Search"
              class="mr-16"
              color="secondary"
              hide-details
              style="max-width: 220px"
            >
              <template
                v-if="$vuetify.breakpoint.mdAndUp"
                v-slot:append-outer
              >
                <v-btn
                  class="mt-n2 ml-n2"
                  fab
                  small
                  elevation="2"
                  height="44"
                  width="44"
                  @click="searchKeyWord"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-dialog
              v-model="dialog"
              max-width="900px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  New Manual
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="editedItem.name"
                          label="Manual Name"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="editedItem.itemTypeId"
                          :items="itemTypes"
                          item-value="id"
                          item-text="displayText"
                          label="Selece Manual Belong To"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="editedItem.specifyKeyword"
                          label="Specify Keyword (Watch Note *)"
                        />
                        <h5>
                          * Specify Keyword Must Follow Example: 500205-071,x520,123 or 500205-071
                          <br>
                          * Case Sensitive, and not allowed space between each keyword
                          <br>
                          * Use  ,  to separate each keyword
                        </h5>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="editedItem.documentUrl"
                          label="Document Url"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-card>
                          <v-card-subtitle>
                            Quick Manual Or Description
                          </v-card-subtitle>
                          <v-card-text>
                            <v-dialog
                              v-model="imageUploadDialog"
                              width="500"
                            >
                              <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                  Insert Image
                                </v-card-title>
                                <v-card-text>
                                  <v-card-actions>
                                    <v-file-input
                                      v-model="uploadImage"
                                      label="Image Upload"
                                      accept="image/png, image/jpeg, image/bmp"
                                      dense
                                    />
                                    <v-btn
                                      class="ml-1"
                                      text
                                      @click="imageUpload"
                                    >
                                      Insert
                                    </v-btn>
                                  </v-card-actions>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                            <v-row>
                              <v-col
                                cols="12"
                              >
                                <v-card-actions
                                  v-if="richEditor"
                                  class="TipTapEditorBar"
                                >
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('bold') }"
                                    @click="richEditor.chain().focus().toggleBold().run()"
                                  >
                                    <v-icon small>
                                      mdi-format-bold
                                    </v-icon>
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('italic') }"
                                    @click="richEditor.chain().focus().toggleItalic().run()"
                                  >
                                    <v-icon small>
                                      mdi-format-italic
                                    </v-icon>
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('code') }"
                                    @click="richEditor.chain().focus().toggleCode().run()"
                                  >
                                    <v-icon small>
                                      mdi-xml
                                    </v-icon>
                                  </button>
                                  <button @click="richEditor.chain().focus().unsetAllMarks().run()">
                                    <v-icon small>
                                      mdi-eraser-variant
                                    </v-icon>
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('paragraph') }"
                                    @click="richEditor.chain().focus().setParagraph().run()"
                                  >
                                    <v-icon small>
                                      mdi-format-paragraph
                                    </v-icon>
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('heading', { level: 1 }) }"
                                    @click="richEditor.chain().focus().toggleHeading({ level: 1 }).run()"
                                  >
                                    h1
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('heading', { level: 2 }) }"
                                    @click="richEditor.chain().focus().toggleHeading({ level: 2 }).run()"
                                  >
                                    h2
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('heading', { level: 3 }) }"
                                    @click="richEditor.chain().focus().toggleHeading({ level: 3 }).run()"
                                  >
                                    h3
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('heading', { level: 4 }) }"
                                    @click="richEditor.chain().focus().toggleHeading({ level: 4 }).run()"
                                  >
                                    h4
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('heading', { level: 5 }) }"
                                    @click="richEditor.chain().focus().toggleHeading({ level: 5 }).run()"
                                  >
                                    h5
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('heading', { level: 6 }) }"
                                    @click="richEditor.chain().focus().toggleHeading({ level: 6 }).run()"
                                  >
                                    h6
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('bulletList') }"
                                    @click="richEditor.chain().focus().toggleBulletList().run()"
                                  >
                                    <v-icon small>
                                      mdi-format-list-bulleted-square
                                    </v-icon>
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('orderedList') }"
                                    @click="richEditor.chain().focus().toggleOrderedList().run()"
                                  >
                                    <v-icon small>
                                      mdi-format-list-numbered
                                    </v-icon>
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('codeBlock') }"
                                    @click="richEditor.chain().focus().toggleCodeBlock().run()"
                                  >
                                    <v-icon small>
                                      mdi-file-code
                                    </v-icon>
                                  </button>
                                  <button
                                    :class="{ 'is-active': richEditor.isActive('blockquote') }"
                                    @click="richEditor.chain().focus().toggleBlockquote().run()"
                                  >
                                    <v-icon small>
                                      mdi-format-horizontal-align-right
                                    </v-icon>
                                  </button>
                                  <button @click="richEditor.chain().focus().setHorizontalRule().run()">
                                    <v-icon small>
                                      mdi-arrow-split-horizontal
                                    </v-icon>
                                  </button>
                                  <button @click="richEditor.chain().focus().setHardBreak().run()">
                                    <v-icon small>
                                      mdi-format-page-break
                                    </v-icon>
                                  </button>
                                  <button @click="imageUploadDialog = true">
                                    <v-icon small>
                                      mdi-file-image-plus
                                    </v-icon>
                                  </button>
                                  <v-spacer></v-spacer>
                                  <button @click="richEditor.chain().focus().undo().run()">
                                    <v-icon small>
                                      mdi-undo-variant
                                    </v-icon>
                                  </button>
                                  <button @click="richEditor.chain().focus().redo().run()">
                                    <v-icon small>
                                      mdi-redo-variant
                                    </v-icon>
                                  </button>
                                </v-card-actions>
                              </v-col>
                              <v-col cols="12">
                                <editor-content
                                  class="ProseMirror"
                                  :editor="richEditor"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </material-card>
    <div class="text-center">
      <v-pagination
        v-model="meta.current_page"
        :length="meta.last_page"
        circle
        :total-visible="7"
        @input="changePage"
      />
    </div>
    <div class="py-3" />
  </v-container>
</template>

<script>
  // import itemTypeApi from '@/api/item-type'
  import manualApi from '@/api/manual'
  import imageApi from '@/api/image'
  import { get } from 'vuex-pathify'
  import store from '@/store'
  import { Editor, EditorContent } from '@tiptap/vue-2'
  import StarterKit from '@tiptap/starter-kit'
  import Image from '@tiptap/extension-image'
  export default {
    name: 'Manual',
    components: { EditorContent },
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'ItemType', value: 'itemType.name' },
          { text: 'ItemCategory', value: 'itemType.item_category.name' },
          { text: 'Specify Keyword', value: 'specifyKeyword' },
          { text: 'Author', value: 'author' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        // desserts: [],
        manualData: [],
        richEditor: null,
        editedIndex: -1,
        imageUploadDialog: false,
        editedItem: {
          id: 0,
          name: '',
          description: null,
          itemTypeId: '',
          specifyKeyword: '',
          documentUrl: '',
        },
        defaultItem: {
          id: 0,
          name: '',
          description: null,
          itemTypeId: '',
          specifyKeyword: '',
          documentUrl: '',
        },
        uploadImage: null,
        // itemTypeList: [],
        // itemCategoryList: [],
        meta: {},
        search: false,
        keyWord: '',
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Manual' : 'Edit Manual'
      },
      ...get('app/', ['itemTypes']),
    },

    watch: {
      dialog (newVal, oldVal) {
        if (newVal === false) this.close()
        if (newVal === true && this.editedIndex === -1) this.richEditor.commands.setContent('<p>Thanks for tiptap with Vue.js. 🎉</p>')
        // val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.initialize()
    },
    mounted () {
      this.richEditor = new Editor({
        content: '<p>Thanks for tiptap with Vue.js. 🎉</p>',
        extensions: [
          StarterKit,
          Image,
        ],
      })
    },

    beforeDestroy () {
      this.richEditor.destroy()
    },

    methods: {
      initialize () {
        this.loadTableData()
        // this.loadItemTypeData()
        store.set('app/getItemTypes')
      },
      loadTableData () {
        manualApi.index(this.search, false, this.keyWord).then(response => {
          this.manualData = response.data.data
          this.meta = response.data.meta
        }).catch(error => {
          console.log(error)
        })
      },
      /*
      loadItemTypeData () {
        itemTypeApi.typeAndCategory().then(response => {
          this.itemTypeList = response.data.data.itemType
          this.itemCategoryList = response.data.data.itemCategory
        }).catch(error => {
          console.log(error)
        })
      },
      */
      searchKeyWord () {
        this.search = true
        this.loadTableData()
      },
      resetAndLoad () {
        this.search = false
        this.loadTableData()
      },
      changePage (page) {
        manualApi.loadPageData(page, this.meta.path, this.search, this.keyWord).then(response => {
          this.manualData = response.data.data
          this.meta = response.data.meta
          this.$vuetify.goTo(0)
        }).catch(error => {
          console.log(error)
        })
      },
      editItem (item) {
        this.editedIndex = item.id
        this.richEditor.commands.setContent(item.description)
        this.editedItem = Object.assign({}, item)
        if (this.editedItem.specifyKeyword) this.editedItem.specifyKeyword = this.editedItem.specifyKeyword.toString()
        if (this.editedItem.itemType) this.editedItem.itemTypeId = this.editedItem.itemType.id
        this.dialog = true
      },
      imageUpload () {
        const imageData = new FormData()
        imageData.append('manual_image', this.uploadImage)
        imageData.append('manual_id', this.editedIndex)
        imageApi.store(imageData).then(response => {
          if (response.data.code === 200) this.addImage(response.data.data)
        }).catch(error => {
          console.log(error)
        })
      },
      addImage (url) {
        this.richEditor.chain().focus().setImage({ src: url }).run()
        this.imageUploadDialog = false
      },
      deleteItem (item) {
        this.editedIndex = item.id
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        console.log(this.editedIndex)
        manualApi.delete(this.editedIndex).then(response => {
          this.closeDelete()
          this.loadTableData()
        }).catch(error => {
          console.log(error)
        })
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        this.editedItem.description = this.richEditor.getHTML()
        if (this.editedItem.specifyKeyword) {
          this.editedItem.specifyKeyword = this.editedItem.specifyKeyword.split(',')
        }
        if (this.editedIndex > -1) {
          manualApi.update(this.editedItem).then(() => {
            this.loadTableData()
          }).catch(error => { console.log(error) })
        } else {
          manualApi.store(this.editedItem).then(() => {
            this.loadTableData()
          }).catch(error => { console.log(error) })
        }
        this.close()
      },
    },
  }
</script>

<style scoped>

</style>
